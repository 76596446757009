import React from "react"
import OnlineCourses from "../allcourses/OnlineCourses"
// import Heading from "../common/heading/Heading"
import "../allcourses/courses.css"
// import { coursesCard } from "../../dummydata"

const HAbout = () => {
  return (
    <>
    <br /><br />
      <section className='homeAbout'>
        <OnlineCourses />
      </section>
    </>
  )
}

export default HAbout
